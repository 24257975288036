<template>
  <el-container class="container">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      商品管理
    </el-header>
    <el-container style="height:calc(100vh - 110px);">
      <el-container class="container1 left-container">
        <el-main class="main left-main">
          <div class="user">
            <div class="name">
              <img src="@/assets/images/avatar.png" alt="">
              <div>
                赵小美
              </div>
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              盘点编号
            </div>
            <div class="cell-r">
              ZD202102016600
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              盘点仓库
            </div>
            <div class="cell-r">
              深圳仓库
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              盘点类型
            </div>
            <div class="cell-r">
              月盘
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              盘点商品
            </div>
            <div class="cell-r">
              5件
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              创建时间
            </div>
            <div class="cell-r">
              2021/01/31 12:00
            </div>
          </div>
          <div class="cell">
            <div class="cell-l">
              备注
            </div>
            <div class="cell-r">
              无
            </div>
          </div>

          <div class="mark">
            <div class="mark-title">
              盘点说明
            </div>
            <div class="mark-ipt">
              <el-input type="textarea" v-model="markInput" :rows="3" resize="none" placeholder="输入盘点说明" maxlength="300"
                show-word-limit>
              </el-input>
            </div>
          </div>
        </el-main>
      </el-container>
      <el-container class="container1 right-container">
        <el-header>
          <div class="main-title">
            商品信息
            <div class="title-right">
              <el-input v-model="searchVal" prefix-icon="el-icon-search" placeholder="请输入商品名称或条码"
                @keyup.enter.native="searchChange"></el-input>
            </div>
          </div>
        </el-header>
        <el-main class="main right-main">
          <div class="body">
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：<span class="text">10箱</span>
                  </div>
                  <div class="left right">
                    盘点数量：<div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：<span class="text">10箱</span>
                  </div>
                  <div class="left right">
                    盘点数量：<div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：<span class="text">10箱</span>
                  </div>
                  <div class="left right">
                    盘点数量：<div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：<span class="text">10箱</span>
                  </div>
                  <div class="left right">
                    盘点数量：<div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-title">
                <div class="name-close">
                  <div class="name">菲灵魔法定型发胶</div>
                </div>
                <div class="size">500ml</div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：<span class="text">10箱</span>
                  </div>
                  <div class="left right">
                    盘点数量：<div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item">
                <div class="top">
                  <div class="left">生产日期：<span class="text">2020年3月16日</span></div>
                </div>
                <div class="center">
                  批次号码：<span class="text">202102030002</span>
                </div>
                <div class="bottom">
                  <div class="left">库存数量：<span class="text">10箱</span>
                  </div>
                  <div class="left right">
                    盘点数量：<div class="stepper">
                      <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                      </el-input-number>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-footer>
      <el-button type="primary" round @click="submitTaking">
        提交盘点结果
      </el-button>
    </el-footer>

  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        timePicker: new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) > 9 ? new Date().getMonth() + 1 :
          '0' + (new Date().getMonth() + 1)) + '-' + (new Date().getDate() > 9 ? new Date().getDate() : '0' +
          new Date().getDate()),
        markInput: '', // 盘点说明
        stepperNum: '', // 入库数量
        // 添加商品
        searchVal: '',


      }
    },
    computed: {},
    watch: {},

    mounted() {},
    methods: {
      // 返回上级
      returnOn() {
        this.$confirm('返回后盘点商品依然无法出入库、无法售卖，且已经盘点的结果不会保存，确定要中断盘点吗？', '特别说明', {
          confirmButtonText: '继续盘点',
          cancelButtonText: '中断盘点',
          type: 'warning',
          center: true
        }).then(() => {

        }).catch(() => {
          this.$router.push({
            name: 'TakingQuery'
          })
        });

      },
      // 搜索
      searchChange() {
        if (this.searchVal.trim()) {
          console.log(this.searchVal.trim());
        } else {
          this.$message.error('搜索内容不能为空')
        }
      },
      submitTaking() {
        this.$message({
          message: '提交成功',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push({
            name: 'TakingQuery'
          })
        }, 1500);
      },

    }
  };

</script>
<style lang="less" scoped>
  .container {
    height: calc(100vh);
    background-color: #f9f9f9;

    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;

      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }

    .container1 {
      background-color: #fff;
      box-shadow: 0 0 2px #ccc;
      color: #666;

      .main-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 50px;
        border-bottom: 1px solid #f2f2f2;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;

        .title-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-weight: normal;
          font-size: 12px;

          .btn {
            width: 70px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #409eff;
            border-radius: 5px;
            margin-left: 10px;
          }

          .btn1 {
            color: #409eff;
          }

          .btn2 {
            background-color: #409eff;
            color: #fff;
          }
        }
      }

      .body {
        padding: 0 15px;
      }
    }

    .left-container {
      width: 50%;
      margin: 20px 10px 20px 20px;
      padding: 40px 20px 10px;


      .left-main {
        .user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;

            img {
              width: 35px;
              margin-right: 10px;
            }
          }

          .type {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            color: #999;

            span {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              margin-right: 5px;
            }

            .round-point {
              background-color: #52c1f5;

            }

            .round-point1 {
              background-color: #ffa94c;

            }

            .round-point2 {
              background-color: #51d351;

            }
          }
        }

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 50px;
          border-bottom: 1px solid #f2f2f2;

          &:last-child {
            border-bottom: none;
          }

          .cell-l {
            color: #999;
          }

          .cell-r {
            font-size: 12px;
          }
        }

        .mark {
          margin-top: 20px;

          .mark-title {
            line-height: 40px;
          }
        }
      }
    }

    .right-container {
      width: 50%;
      margin: 20px 20px 20px 10px;

      /deep/ .el-header {
        border-bottom: 0;

        .main-title {
          border-bottom: 0;
        }
      }
    }

    .card {
      padding: 15px;
      background-color: #f9f9f9;
      border: 1px solid #f2f2f2;
      border-radius: 10px;
      padding-right: 20px;
      margin-bottom: 15px;
      font-size: 12px;

      .card-title {
        border-bottom: 1px solid #f2f2f2;

        .name-close {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          .close {
            font-size: 18px;
            color: #999;
          }
        }

        .size {
          margin-bottom: 10px;
          color: #999;

        }
      }

      .card-item {
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;
        color: #999;

        &:last-child {
          border-bottom: none;
        }

        .text {
          color: #666;
        }

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .right {
            font-size: 18px;
            color: #999;

            span {
              margin-left: 10px;
            }
          }
        }

        .center {
          margin-bottom: 10px;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

          }
        }
      }

      .add-btn {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        color: #409eff;

        &::before {
          margin-right: 5px;
        }
      }
    }

    .el-footer {
      width: 100%;
      background-color: #fff;
      border-top: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-button {
        width: 30%;
      }
    }
  }

  /deep/ .el-input {
    .el-input__inner {
      border-radius: 50px;
      height: 35px;
      font-size: 12px;
      background-color: #f9f9f9;
    }
  }

  .stepper {

    /deep/ .el-input-number {
      width: 90px;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-input-number__decrease,
      .el-input-number__increase {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: #ccc;
        font-weight: bold;
      }

      .el-input {
        width: 100px;
        height: 34px;
        background-color: transparent;

        .el-input__inner {
          height: 20px;
          line-height: 20px;
          padding: 0 15px;
          border-color: #73a0fa;
          background-color: transparent;
          color: #999;
          font-weight: bold;
          border: none;
        }
      }
    }
  }

  .el-main {
    padding: 0 !important;
  }

</style>
